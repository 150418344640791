import React, { useState, useEffect } from "react";
//Redux
import { connect, useDispatch } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'
//axios
import axiosConfig from "util/axios";
// @material-ui/core
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Skeleton from '@material-ui/lab/Skeleton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import StarBorder from '@material-ui/icons/StarBorder';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// @material-ui/icons
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import RedeemIcon from '@material-ui/icons/Redeem';
import PeopleIcon from '@material-ui/icons/People';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Mail from '@material-ui/icons/Mail';
import ShareIcon from '@material-ui/icons/Share';

// core components
// import Button from "components/CustomComponents/CustomButtons/Button.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import Card from "components/CustomComponents/Card/Card.js";
import CardBody from "components/CustomComponents/Card/CardBody.js";
import Dialog from "components/CustomComponents/Dialog/Dialog";
import Progress from 'components/CustomComponents/Progress/Progress'
import AlertDialog from "components/CustomComponents/Dialog/AlertDialog";
// Views
import ChangePassword from 'components/Views/ChangePassword/ChangePassword';
import FriendList from 'components/Views/Profile/FriendList';


// styles
import styles from "assets/jss/components/views/profileStyle";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

//Alert
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// Tooltip
const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}



const InstructionAssessment = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [dialogState, setDialogState] = useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [friendsDialog, setFriendsDialog] = React.useState(false);
    const [openCollapse, setOpenCollapse] = React.useState(false);
    const [logoutLoading, setLogoutLoading] = useState(false);

    const imageUploader = React.useRef(null);
    const [picLoading, setPicLoading] = React.useState(false)

    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState("success");


    /*-----------------------handle Image Upload --------------------*/
    const handleImageUpload = async e => {
        try {
            setPicLoading(true)
            const [file] = e.target.files;
            const base64 = await convertBase64(file);
            await dispatch(questActions.uploadProfilePicture(base64))
            setPicLoading(false)
        } catch (error) {
            setPicLoading(false)
            console.log(error);
        }
    };

    /*-----------------------convert file to Base64 --------------------*/
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    /*----------------------- handle Collapse --------------------*/
    const handleClick = () => {
        setOpenCollapse(!openCollapse);
        if (!openCollapse)
            props.onInitSharedData(props.userInfo.userId, props.quest.questId)
    };

    useEffect(() => {
        if (openCollapse && !props.loadingSharedData) {
            if (props.sharedData.sharedList.length === 0) {
                setOpenCollapse(false)
                setAlertType("info")
                setAlertMessage("Friends list not available")
            }

        }
    }, [openCollapse, props.loadingSharedData])

    /*----------------------- Logout --------------------*/
    const Logout = () => {
        setLogoutLoading(true)
        let isRemember = null;
        let userName = null;
        let password = null;
        let apptype = props.apptype;
        let AppId = sessionStorage.getItem('AppId');
        isRemember = localStorage.getItem('remember')

        if (typeof window.Android !== 'undefined') {
            userName = localStorage.getItem('userName')
            password = localStorage.getItem('password')
            window.Android.onLogout(props.userInfo.userName, false)
        }
        else if (typeof window.flutter_inappwebview !== 'undefined') {
            userName = localStorage.getItem('userName')
            password = localStorage.getItem('password')
            window.flutter_inappwebview.callHandler('onLogout', props.userInfo.userName, isRemember);
        }
        if (typeof onLogout !== 'undefined') {
            userName = localStorage.getItem('userName')
            password = localStorage.getItem('password')
            window.onLogout(props.userInfo.userName, isRemember)
        }
        axiosConfig().get('/QuestUser/Logout', null)
            .then(response => {
                props.logoutUser()
                localStorage.clear();
                sessionStorage.clear();
                setLogoutLoading(false)

                if ((typeof window.Android !== 'undefined' || typeof onLogout !== 'undefined')) {
                    props.history.push({ pathname: '/', search: `?userName=${userName}&password=${password}${apptype ? `&AppType=${apptype}` : ''}` })
                } else {
                    let SearchParams = ""
                    if (apptype !== null && apptype !== 'null')
                        SearchParams = `?AppType=${apptype}`

                    if (AppId)
                        SearchParams = `${SearchParams.length ? SearchParams + '&' : "?"}AppId=${AppId}`

                    props.history.push({ pathname: '/', search: SearchParams })
                }

            }).catch((error) => {
                if (error.response.data) {
                    console.log(error.response.data.error)
                }
            });
    }

    /*-----------------------handle Close Dialog--------------------*/
    const handleCloseDialog = () => {
        setDialogState(false);
    };

    /*-----------------------handle Close Dialog--------------------*/
    const handleOpenDialog = () => {
        setDialogState(true);
    };

    /*-----------------------handle Close Dialog--------------------*/
    const onStart = () => {
        setDialogState(false);
        setAlertType("success")
        setSnackbarMessage("Your password has been changed. Please Logout and Login with new password.");
        //props.history.push('/task')
    }

    /*-----------------------handle Close Snackbar--------------------*/
    const handleClose = () => {
        setSnackbarMessage("");
    }

    /*-----------------------handle FriedsDialog Close --------------------*/
    const handleCloseFriedsDialog = () => {
        setFriendsDialog(false);
    };

    /*-----------------------handle FriedsDialog Open --------------------*/
    const handleOpenFriedsDialog = () => {
        setFriendsDialog(true);
        props.onInitSharedData(props.userInfo.userId, props.quest.questId)
    };

    /*----------------------- Post Shared Data --------------------*/
    const postSharedData = (postData) => {

        let bodyData = [...postData].map(value => {
            let newValue = {}
            newValue["userId"] = value.userId
            newValue["questId"] = props.quest.questId
            newValue["isShared"] = value.isShared
            return newValue
        })
        dispatch(questActions.postSharedData(bodyData)).then(() => {
            setFriendsDialog(false);
            setAlertType("success")
            setSnackbarMessage("Sharing Successful.");
        }).catch(error => {
            setAlertType("error")
            setSnackbarMessage(error.message)
        })
    }

    /*----------------------- Close Alert  --------------------*/
    const onCloseAlert = () => {
        setAlertMessage("")
    }

    console.log(props.userInfo);

    return (
        <React.Fragment>
            {/*----- Spinner---- */}
            <Progress color="secondary" open={logoutLoading || props.loadingSharedData} />


            {/* ------Snackbar------ */}
            <Snackbar open={snackbarMessage ? true : false} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertType}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <AlertDialog
                maxWidth="xs"
                open={alertMessage ? true : false}
                type={alertType}
                ContentText={alertMessage}
                ContentTextStyle={{ color: 'black', marginBottom: 0 }}
                title={null}
                titleStyle={{ paddingBottom: 0 }}
                cancelable={() => { }}
                action={[{ text: 'OK', onPress: () => onCloseAlert() }
                ]}
            />

            {/*-----Dialog----- */}
            <Dialog open={dialogState} maxWidth={"xs"} fullScreen={fullScreen} TransitionComponent={Transition} >
                <ChangePassword onClose={handleCloseDialog} onStart={onStart} />
            </Dialog>

            {/*-----Dialog Friends----- */}
            <Dialog open={(friendsDialog && !props.loadingSharedData)} maxWidth={"xs"} scroll="paper" TransitionComponent={Transition} >
                <FriendList
                    open={friendsDialog && !props.loadingSharedData}
                    data={props.sharedData.friendsList}
                    onClose={handleCloseFriedsDialog}
                    onPost={postSharedData}
                    loading={props.loadingPost}
                />
            </Dialog>




            <div className='page-container background-home'>
                {/*-----Grid----- */}

                <GridContainer >
                    <GridItem xs={9} sm={9} md={9}>
                        <div style={{ color: "#fff", padding: 20, display: 'flex', alignItems: "center" }}>
                            <div className={classes.profilePicContainer}>
                                {props.infoLoading || picLoading ? <Skeleton animation="wave" variant="circle" width={70} height={70} />
                                    : <React.Fragment>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageUpload}
                                            ref={imageUploader}
                                            style={{ display: "none" }}
                                        />
                                        <div onClick={() => imageUploader.current.click()} >
                                            <Avatar
                                                src={props.userInfo.profilePic}
                                                className={classes.profilePicAvatar}
                                            />
                                        </div>
                                    </React.Fragment>
                                }
                            </div>


                            <div style={{ flexDirection: "column", display: 'flex' }}>
                                <Typography variant="subtitle1" style={{ fontWeight: "bold", lineHeight: 1 }}>
                                    {props.infoLoading || picLoading ? <Skeleton animation="wave" width={210} /> : "Hello " + props.userInfo.firstName + ","}
                                </Typography>
                                <Typography variant="caption" >
                                    {props.infoLoading || picLoading ? <Skeleton animation="wave" width={210} /> :( props.userInfo.emailId || props.userInfo.userName)}
                                </Typography>

                                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                                    {props.infoLoading || picLoading ? <Skeleton animation="wave" width={210} />
                                        : <samp style={{ cursor: "pointer", textDecoration: 'underline' }} onClick={Logout} >
                                            {`Logout`}
                                        </samp>}
                                </Typography>

                            </div>
                        </div>
                    </GridItem>
                </GridContainer>


                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <main className={classes.layout}>
                            <Card className={classes.cardStyle}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Paper elevation={0} style={{ borderTopRightRadius: 20, borderTopLeftRadius: 20 }}>
                                            <CardBody className={classes.infoText}>

                                                <List dense={false}>
                                                    {localStorage.getItem('PASSWORD_TYPE') === 'PASSWORD'
                                                        ? <React.Fragment>
                                                            <ListItem>
                                                                <ListItemAvatar>
                                                                    <LockOpenIcon />
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    disableTypography={true}
                                                                    primary={<Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                                                                        <samp style={{ cursor: "pointer", textDecoration: 'underline', color: "#0000FF" }} onClick={handleOpenDialog} >
                                                                            Change Password
                                                                        </samp>
                                                                    </Typography>}
                                                                />
                                                            </ListItem>
                                                            <Divider variant="inset" component="li" />
                                                        </React.Fragment>
                                                        : null
                                                    }

                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Mail />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            disableTypography={true}
                                                            primary={<Typography style={{ fontWeight: "bold" }}> Email for Support :  {props.configs.supportEmail}</Typography>}
                                                        />

                                                    </ListItem>

                                                    <Divider variant="inset" component="li" />
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <MonetizationOnIcon />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            disableTypography={true}
                                                            primary={<Typography style={{ fontWeight: "bold" }}> Total Points Earned : {props.questProgress.points}</Typography>}
                                                        />

                                                        <ListItemSecondaryAction>
                                                            <BootstrapTooltip title=" Share with friends">
                                                                <IconButton edge="end" aria-label="delete" onClick={handleOpenFriedsDialog}>
                                                                    <ShareIcon />
                                                                </IconButton>
                                                            </BootstrapTooltip>

                                                        </ListItemSecondaryAction>
                                                    </ListItem>

                                                    <Divider variant="inset" component="li" />

                                                    {/* <ListItem>
                                                        <ListItemAvatar>
                                                            <RedeemIcon />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            disableTypography={true}
                                                            primary={<Typography variant="subtitle1" style={{ fontWeight: "bold" }}> Redeem Points</Typography>}
                                                        />
                                                    </ListItem> */}

                                                    {/* <Divider variant="inset" component="li" /> */}

                                                    <ListItem style={{ paddingBottom: 0 }}>
                                                        <ListItemAvatar>
                                                            <EmojiFlagsIcon />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            disableTypography={true}
                                                            primary={<Typography variant="subtitle1" style={{ fontWeight: "bold" }}>Quest {props.quest.questName}</Typography>}
                                                        />
                                                    </ListItem>

                                                    <ListItem style={{ paddingBottom: 0 }}>
                                                        <ListItemAvatar >
                                                            <React.Fragment />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            disableTypography={true}
                                                            primary={<Typography variant="subtitle1" style={{ fontWeight: "bold" }}>My Missions</Typography>}
                                                        />
                                                    </ListItem>

                                                    {props.questContent.add.missions.filter(data => data.isAcademic).map(mission =>
                                                        <ListItem style={{ paddingBottom: 0 }} key={mission.missionId}>
                                                            <ListItemAvatar >
                                                                <React.Fragment />
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                disableTypography={true}
                                                                primary={<Typography variant="caption" style={{ fontWeight: "bold" }}>
                                                                    {`${mission.missionDisplayName} : ${mission.progress.points}`}
                                                                </Typography>
                                                                }
                                                            />
                                                        </ListItem>
                                                    )}

                                                    <Divider variant="inset" component="li" />

                                                    <ListItem button onClick={handleClick}>
                                                        <ListItemAvatar>
                                                            <PeopleIcon />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            disableTypography={true}
                                                            primary={<Typography variant="subtitle1" style={{ fontWeight: "bold" }}>My Friends Status(Points)</Typography>}
                                                        />
                                                        {openCollapse ? <ExpandLess /> : <ExpandMore />}
                                                    </ListItem>

                                                    <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                                                        <List component="div" disablePadding>
                                                            {props.loadingSharedData
                                                                ? null
                                                                : props.sharedData.sharedList.map((data, index) =>
                                                                    <ListItem button className={classes.nested} key={"FRDLIST" + index}>
                                                                        <ListItemIcon>
                                                                            <StarBorder />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            disableTypography={true}
                                                                            primary={<Typography variant="caption" style={{ fontWeight: "bold" }}>
                                                                                {`${data.name} : ${data.score}`}
                                                                            </Typography>
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                )}
                                                        </List>
                                                    </Collapse>


                                                </List>
                                            </CardBody>
                                        </Paper>
                                    </GridItem>

                                </GridContainer>
                            </Card>
                        </main>
                    </GridItem>
                </GridContainer>

            </div>
        </React.Fragment>

    );
}


const mapStateToProps = state => {
    return {
        questProgress: state.content.current_quest_progress,
        questContent: state.content.contentlist,

        quest: state.quest.current_quest,

        infoLoading: state.user.loading,
        userInfo: state.user.userinfo,

        loadingSharedData: state.shareddata.fetch_loading,
        sharedData: state.shareddata.shareddata,
        loadingPost: state.shareddata.post_loading,

        apptype: state.appconfigs.apptype,
        configs: state.appconfigs.configs
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitSharedData: (userId, QuestId) => dispatch(questActions.initSharedData(userId, QuestId)),
        logoutUser: () => dispatch(questActions.logoutUser())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstructionAssessment);
