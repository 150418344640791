import React, { useEffect } from "react";
import { Redirect } from 'react-router-dom';
//Redux
import { connect } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'

//commonfunctions
import { clevertapEvents } from "util/commonfunctions";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';

// @material-ui/icons
import Typography from '@material-ui/core/Typography';


// core components
import Button from "components/CustomComponents/CustomButtons/Button.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import Card from "components/CustomComponents/Card/Card.js";
import CardIcon from "components/CustomComponents/Card/CardIcon.js";
import CardBody from "components/CustomComponents/Card/CardBody.js";
import Info from 'components/CustomComponents/Typography/Info'
import PageNavigation from 'components/CustomComponents/Navbars/PageNavigation'
// import Progress from "components/CustomComponents/Progress/Progress"
// img
import avatar from "assets/img/documents.png";

// styles
import styles from "assets/jss/components/views/InstructionAssessmentStyle";

const useStyles = makeStyles(styles);



const InstructionAssessment = (props) => {
    // const [progress, setProgress] = React.useState(false);
    const classes = useStyles();

    /*---------------------init Questions List--------------------*/
    useEffect(() => {
        if (props.content.testCode) {
            props.onSetCurrentTest(props.content.testCode)
            props.onInitQuestions(props.content.testCode)
        }
    }, [props.content])

    /*----------------------Begin Test----------------------------*/
    const onBeginTest = () => {
        props.setCurrentTestHistory({})
        clevertapFun("Teststared")
        props.history.push('/assessment');
    }

    /*-----------------------clevertap--------------------*/
    const clevertapFun = (EventName) => {
        const eventData = JSON.stringify({
            'MisssionName': props.mission.missionName,
            'TestName': props.test.testName
        });
        clevertapEvents(EventName, eventData);
    }


    if (props.content.testCode === undefined) {
        return (
            <Redirect to="task" />
        )
    }

    return (
        <div>
            {/*-----PageNavigation----- */}
            <PageNavigation logoText={props.content.contentDisplayName} />

            {/*----- Spinner---- */}
            {/* {props.loading ? <Progress color="secondary" open={props.loading} /> : null} */}

            {/*-----page-container----- */}
            <div className='assm-instruction-container'>

                {/*-----Grid----- */}
                <GridContainer className={classes.successColor}>
                    <GridItem xs={12} sm={12} md={12}>
                        <CardIcon className={classes.cardIconStyle}>
                            {props.loading
                                ? <Skeleton variant="circle" width={70} height={70} />
                                : <img className={classes.imageStyle} src={avatar} alt="..." />
                            }
                        </CardIcon>
                    </GridItem>
                </GridContainer>

                {/* <main className={classes.layout}> */}

                {/* <div>
                            <Skeleton variant="circle" animation="wave" width={40} />
                            <Skeleton variant="text" animation="wave" />
                            <Skeleton variant="rect" animation="wave" width={210} />
                        </div> */}

                <GridContainer className={classes.gridContainer1}>
                    <GridItem xs={12} sm={12} md={6} className={classes.centerContainer}>
                        <Card className={classes.cardStyle1}>
                            <Paper elevation={0} style={{ borderTopRightRadius: 20, borderTopLeftRadius: 20, height: '100%' }}>
                                <CardBody className={classes.infoText}>
                                    <GridContainer >
                                        <GridItem xs={12} sm={12} md={8} className={classes.centerContainer}>
                                            <div className={classes.earnPoints} color="primary">
                                                <Typography variant='subtitle1' component='h2' className={classes.infoText} style={{ fontWeight: 'bold' }}>
                                                    {props.loading ? <Skeleton variant="text" animation="wave" width={200} /> : 'Instruction For Assessment'}
                                                </Typography>


                                                <div style={{ flexDirection: "row", display: 'flex', justifyContent: 'center' }}>
                                                    {props.loading
                                                        ? <Skeleton variant="text" animation="wave" width={100} />
                                                        : <React.Fragment>
                                                            <div className={classes.taskDiv}>
                                                                <Info >Time: </Info>
                                                                <Info>{props.test.totalDuration} min</Info>
                                                            </div>
                                                            <div className={classes.timeDiv}>
                                                                < Info > Questions: </Info>
                                                                <Info>{props.test.totalQuestion}</Info>
                                                            </div>
                                                        </React.Fragment>
                                                    }

                                                </div>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Paper>
                        </Card>
                    </GridItem>
                </GridContainer>


                <GridContainer className={classes.gridContainer2}>
                    <GridItem xs={12} sm={12} md={6} className={classes.gridItem}>
                        <Card className={`scroll ${classes.cardStyle2}`}>
                            <Paper elevation={0} style={{ borderTopRightRadius: 20, borderTopLeftRadius: 20, height: '100%' }}>

                                <CardBody className={classes.infoText}>
                                    <GridContainer >

                                        {props.loading ?
                                            <GridItem xs={12} sm={12} md={8} className={classes.centerContainer}>
                                                <Skeleton variant="text" animation="wave" width="100%" />
                                                <Skeleton variant="text" animation="wave" width="100%" />
                                                <Skeleton variant="text" animation="wave" width="80%" />
                                                <Skeleton variant="text" animation="wave" width="100%" />
                                                <Skeleton variant="text" animation="wave" width="100%" />
                                                <br></br>
                                                <Skeleton variant="text" animation="wave" width="50%" />

                                                <Skeleton variant="rect" animation="wave" width="100%" />
                                            </GridItem>

                                            : <React.Fragment>
                                                <GridItem xs={12} sm={12} md={8} className={classes.centerContainer}>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: props.test.testDirections
                                                        }}
                                                    ></div>

                                                </GridItem>
                                            </React.Fragment>
                                        }
                                    </GridContainer>
                                </CardBody>
                            </Paper>
                        </Card>
                    </GridItem>
                </GridContainer>


                <GridContainer className={classes.gridContainer4}>
                    <GridItem xs={12} sm={12} md={6} className={classes.centerContainer}>
                        <Card className={classes.cardStyle3}>
                            <Paper elevation={0} style={{ borderTopRightRadius: 20, borderTopLeftRadius: 20, height: '100%' }}>

                                <GridContainer >

                                    <GridItem xs={12} sm={12} md={8} className={classes.centerContainer}>
                                        {props.loading ?
                                            <Skeleton variant="rect" height="40px" animation="wave" width="100%" />
                                            : <Button
                                                // disabled={progress ? true : false}
                                                round
                                                fullWidth
                                                className={classes.buttonCenter}
                                                color="secondary"
                                                // onClick={() => props.history.push('/assessment')}
                                                onClick={onBeginTest}
                                            >
                                                Begin Test
                                            </Button>
                                        }
                                    </GridItem>
                                </GridContainer>

                            </Paper>
                        </Card>
                    </GridItem>


                </GridContainer>

                {/* </main> */}
            </div>
        </div >
    );
}


const mapStateToProps = state => {
    return {
        content: state.content.current_content,
        test: state.content.current_test,
        loading: state.questions.loading,
        mission: state.content.current_mission,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetCurrentTest: (testCode) => dispatch(questActions.setCurrentTest(testCode)),
        onInitQuestions: (testCode) => dispatch(questActions.initQuestions(testCode)),
        setCurrentTestHistory: (data) => dispatch(questActions.setCurrentTestHistory(data)),
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(InstructionAssessment);
