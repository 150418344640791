import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import axiosConfig from "util/axios";
//import reducer 
import { useDispatch, useSelector } from 'react-redux';
import * as questActions from 'store/actions/index'

// @material-ui/core
import Slide from '@material-ui/core/Slide';
import Input from '@material-ui/core/Input';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Divider from '@material-ui/core/Divider';

// @material-ui/icons
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
// core components
import Button from "components/CustomComponents/CustomButtons/Button.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import CustomizedDialog from "components/CustomComponents/Dialog/CustomizedDialog";
import AlertDialog from "components/CustomComponents/Dialog/AlertDialog";
// Views
import ForgotPassword from "components/Views/ForgotPassword/ForgotPassword";

//validations
import { checkNull, checkPassword } from "util/validations";

// StoreUserData
import { StoreUserData } from 'util/commonfunctions'

// custom style
import styles from "assets/jss/components/views/signinStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const useStyles = makeStyles(styles)

const SigninContainer = (props) => {

    const classes = useStyles();
    const {
        textColor,
    } = props;


    const inputUnderlineClasss = classNames({
        [classes.inputUnderline]: true,
        [classes['inputUnderline' + textColor]]: textColor,
    });

    const checkboxLabelClass = classNames({
        [classes.checkboxLabelStyle]: true,
        [classes[textColor]]: textColor,
    })

    const forgotPasswordLableClass = classNames({
        [classes.forgotPasswordLable]: true,
        [classes[textColor]]: textColor,
    })



    const dispatch = useDispatch();
    let history = useHistory();
    const InputPassword = useRef(null)
    const configs = useSelector(state => state.appconfigs.configs)
    const apptype = useSelector(state => state.appconfigs.apptype)

    const [state, setState] = useState({
        userName: props.userInfo.userName,
        password: props.userInfo.password,
        errorUserName: "",
        errorPassword: "",
    });

    const [openProgress, setOpenProgress] = useState(false)
    const [remember, setRemember] = useState(props.userInfo.remember)
    const [errorMessage, setErrorMessage] = useState('')
    const [forgotDialog, setForgotDialog] = React.useState(false);
    const [forgotDialogTitle, setForgotDialogTitle] = React.useState("Forgot Password");

    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState("success");

    // ---- click Login --------
    const handleLogin = () => {
        localStorage.setItem('PASSWORD_TYPE', 'PASSWORD'); // use for Change Password

        setOpenProgress(true)
        // Validation
        let validationObject = {};
        checkNull("UserName", state.userName, "Username is required", validationObject);
        checkNull("Password", state.password, "Password is required", validationObject);

        if (Object.keys(validationObject).length != 0) {
            setOpenProgress(false)
            setState({
                ...state,
                errorPassword: "",
                errorUserName: "",
                ...validationObject
            });
        } else {
            logIN()
        }
    }

    const handleCheckChange = (event) => {
        const isChecked = event.target.checked
        setRemember(isChecked);
        if (typeof window.Android !== 'undefined') {
            if (isChecked) {
                window.Android.onRemenberMeClicked(state.userName, state.password, true);
            }
            else {
                //Remember Me Uncheck
                window.Android.onRemenberMeUncheck(state.userName);
            }
        }
        if (typeof onRemenberMeClicked !== 'undefined') {
            if (isChecked) {
                window.onRemenberMeClicked(state.userName, state.password, true);
            }
            else {
                //Remember Me Uncheck
                window.onRemenberMeUncheck(state.userName);
            }
        }

        if (typeof window.flutter_inappwebview !== 'undefined') {
            if (isChecked) {
                window.flutter_inappwebview.callHandler('onRemenberMeClicked', state.userName, state.password, true);
            }
            else {
                //Remember Me Uncheck
                window.flutter_inappwebview.callHandler('onRemenberMeUncheck', state.userName);
            }

        }
    };


    //handle ENTER key press
    const handleKeyPress = (e) => {
        const name = e.target.name;
        const charCode = e.charCode;
        if (charCode == 13) {
            if (name === 'password')
                logIN()
            else if (name === 'userName')
                InputPassword.current.focus()
        }
    }

    const logIN = async () => {
        axiosConfig().post('/QuestUser/AuthenticateUser', {}, {
            auth: {
                username: state.userName,
                password: state.password
            }
        }).then(async response => {
            dispatch(questActions.setAuthError(false))

            if (remember) {
                await StoreUserData(state.userName, state.password, true,'Spectrum')
            }
            sessionStorage.setItem('token', response.data.token);

            await axiosConfig().get('/QuestUser/GetCurrentUser').then(async response => {
                await dispatch(questActions.setUserInfo(response.data))

                if (response.data.userQuestList.length > 0) {
                    dispatch(questActions.initUserQuestList(props.isInitCurrentQuestAndContentList)).then(() => {
                        setOpenProgress(false)
                        navigateTo()
                    })
                } else if (response.data.showDashboard && apptype !== 'Spectrum') {
                    setOpenProgress(false)
                    history.push('/home')
                } else {
                    setOpenProgress(false)
                    navigateTo();
                }
            }).catch(function (error) {
                dispatch(questActions.fetchUserInfoFailed())
            });


        }).catch((error) => {
            setOpenProgress(false)
            if (error.response) {
                dispatch(questActions.setAuthError(true))
                setErrorMessage(error.response.data.error)
            }
        });
    }

    const navigateTo = () => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const actionType = params.get('actionType')
        if (actionType !== null && actionType !== '') {
            if (actionType === 'schedule')
                history.push('/home/connect')
        } else {
            history.push('/home')
        }
    }

    /*-----------------------handle Forgot Password Dialog Open --------------------*/
    const openForgotDialog = () => {
        dispatch(questActions.setUserverificationStatus({}))
        setForgotDialog(true)
    };

    /*-----------------------handle Forgot Password Dialog Close --------------------*/
    const closeForgotDialog = () => {
        setForgotDialog(false);
    };

    /*------------------------handel onSignUp--------------------------------------- */
    const handleOnSignUp = async () => {
        await dispatch(questActions.setInitialUser())
        props.onSignUp()
    }

    /* --------------------------on Close Alert-----------------*/
    const onCloseAlert = () => {
        switch (alertType) {
            case "danger":
                setAlertMessage("")
                break;
            case "success":
                setAlertMessage("")
                break;
            default:
                setAlertMessage("")
        }
    }

    /* --------------------------on Reset Password-----------------*/
    const onResetPassword = (alertType, message) => {
        setAlertType(alertType)
        setAlertMessage(message)
    }

    /*----------------------- Open URL --------------------*/
    const onOpenURL = (URL) => {
        if (typeof window.Android !== 'undefined') {
            window.Android.openNewUrl("", URL)
        } else if (typeof openNewUrl !== 'undefined') {
            window.openNewUrl("", URL)
        } else if (typeof window.flutter_inappwebview !== 'undefined') {
            window.flutter_inappwebview.callHandler('openNewUrl', "", URL);
        } else {
            window.open(URL, "_blank")
        }
    }


    return (
        <React.Fragment>
            {openProgress
                ? < LinearProgress color="primary" style={{ position: 'absolute', width: '95%', left: 10, right: 10 }} />
                : null
            }

            {/* ---AlertDialog--- */}
            <AlertDialog
                maxWidth="xs"
                open={alertMessage ? true : false}
                type={alertType}
                ContentText={alertMessage}
                ContentTextStyle={{ color: 'black', marginBottom: 0 }}
                title={null}
                titleStyle={{ paddingBottom: 0 }}
                cancelable={() => { }}
                action={[{ text: 'OK', onPress: () => onCloseAlert() }
                ]}
            />

            {/* Dialog */}
            <CustomizedDialog
                TransitionComponent={Transition}
                classes={{ paper: classes.paperDialog }}
                scroll="body"
                title={forgotDialogTitle}
                open={forgotDialog}
                fullWidth={true}
                maxWidth="xs"
                onClose={closeForgotDialog}>
                <div style={{ display: 'flex', justifyContent: "center", flexDirection: 'column' }}>
                    <ForgotPassword
                        onChangeTitle={(title) => setForgotDialogTitle(title)}
                        onClose={closeForgotDialog}
                        onResetPassword={onResetPassword}
                    />
                </div>
            </CustomizedDialog>

            <div className={classes.paper}>
                <div className={classes.form}>

                    {/* ....Facebook.......*/}
                    {/* <Button
                        disabled={state.openProgress ? true : false}
                        round
                        variant="contained"
                        color="primary"
                        outlined
                        fullWidth>
                        <FacebookIcon />Facebook
                        </Button> */}

                    {/*....Google.......*/}
                    {/* <Button
                        disabled={state.openProgress ? true : false}
                        round
                        variant="contained"
                        color="primary"
                        outlined
                        fullWidth>
                        Google
                        </Button> */}


                    {/* <div style={{ margin: 10 }}>
                    <Typography variant="caption" > OR</Typography>
                </div> */}

                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="userName" style={{ color: props.textColor }}>Username</InputLabel>
                        <Input
                            classes={{ underline: inputUnderlineClasss }}
                            name="userName"
                            autoComplete="email"
                            autoFocus
                            disabled={openProgress ? true : false}
                            value={state.userName}
                            onChange={(e) => setState({ ...state, userName: e.target.value })}
                            onKeyPress={handleKeyPress}
                        />
                        <FormHelperText className={classes.labelRootError} >{state.errorUserName}</FormHelperText>
                    </FormControl>

                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="password" style={{ color: props.textColor }}>Password</InputLabel>
                        <Input
                            classes={{ underline: inputUnderlineClasss }}
                            name="password"
                            type="password"
                            inputRef={InputPassword}
                            disabled={openProgress ? true : false}
                            autoComplete="current-password"
                            value={state.password}
                            onChange={(e) => setState({ ...state, password: e.target.value })}
                            onKeyPress={handleKeyPress}
                        />
                        <FormHelperText className={classes.labelRootError} >{state.errorPassword}</FormHelperText>
                    </FormControl>

                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            {/*....Create account.......*/}
                            <FormControlLabel
                                className={classes.checkboxStyle}
                                control={
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                        checked={remember}
                                        onChange={handleCheckChange}
                                    />
                                }
                                classes={{ label: checkboxLabelClass }}
                                label="Remember Me"
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <FormControlLabel
                                className={forgotPasswordLableClass}
                                control={<samp style={{ cursor: 'pointer' }} onClick={openForgotDialog}>Forgot Password?</samp>}
                            />
                            {/* <Button link size="normal">
                Forgot Password?
            </Button> */}
                        </GridItem>

                        {/* <GridItem xs={12} sm={12} md={12}>
              <FormControlLabel
                className={classes.forgotPasswordLable}
                control={<samp style={{ cursor: 'pointer' }} onClick={() => {
                  this.props.history.push('/signup')
                }}>Sign Up</samp>}
              />
            </GridItem> */}
                    </GridContainer>
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        {props.isBack
                            && <Button
                                disabled={openProgress ? true : false}
                                round
                                fullWidth
                                variant="contained"
                                color="info"
                                className={classes.submit}
                                onClick={() => history.push('/')}
                            >
                                Back
                            </Button>
                        }
                        <div style={{ width: 10 }}></div>
                        <Button
                            disabled={openProgress ? true : false}
                            round
                            fullWidth
                            variant="contained"
                            color={textColor === "white" ? "white" : "primary"}
                            className={classes.submit}
                            onClick={handleLogin}>
                            Login
                        </Button>

                    </div>
                    <div style={{ margin: "8px 0"  }}>
                        <Divider style={{backgroundColor:'rgb(245 237 237 / 37%)'}} />
                    </div>

                    {props.onExplore
                        && <Button
                            disabled={openProgress ? true : false}
                            round
                            fullWidth
                            variant="contained"
                            color="info"
                            className={classes.submit}
                            onClick={props.onExplore}
                        >
                            I'm a new user. I want to Explore
                        </Button>
                    }

                    {props.onSignUp
                        && <Button
                            disabled={openProgress ? true : false}
                            round
                            fullWidth
                            variant="contained"
                            color="info"
                            className={classes.submit}
                            onClick={handleOnSignUp}
                        >
                            I'm a new user. I want to Sign Up
                        </Button>
                    }


                    {/* <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{ textAlign: 'center', justifyContent: "center", display: 'flex', height: 15 }}>
                <Typography   variant="caption" paragraph display="inline">
                  Don`t have an account?
                </Typography>
                <div className={classes.hyperText}>
                  <samp
                    className={classes.hyperText}
                    style={{ color: "#0000FF", cursor: 'pointer', paddingLeft: 5,fontWeight: 600 }}
                    onClick={() => this.props.history.push('/signup')} > Sign Up</samp>
                </div>
              </div>
            </GridItem>
          </GridContainer> */}




                    {/* <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            {/*....Facebook.......*/}
                    {/* <Button color="facebook">
            Facebook
            </Button>
          </GridItem>
        <GridItem xs={6} sm={6} md={6}>
          {/*....Google.......*/}
                    {/* <Button color="danger">
            Google
            </Button>
        </GridItem>
        </GridContainer> */}

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} style={{ color: props.textColor }}>
                            <Typography
                                // color="textSecondary"

                                variant="caption"
                                paragraph
                                display="inline"
                            >
                                By Clicking Login,You Agree to the SESL
                            </Typography>
                            <br></br>
                            <div className={classes.hyperText} style={{ color: props.textColor }}>
                                <samp className={classes.hyperText} style={{ color: "#fff", cursor: 'pointer', textDecoration: 'underline' }} onClick={() => onOpenURL(configs.tcUrl)} > Privacy Policy </samp>
                                and
                                <samp className={classes.hyperText} style={{ color: "#fff", cursor: 'pointer', textDecoration: 'underline' }} onClick={() => onOpenURL(configs.tcUrl)} > Terms and Conditions</samp>
                            </div>

                        </GridItem>
                    </GridContainer>


                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                        open={errorMessage ? true : false}
                        autoHideDuration={3000}
                        onClose={() => setErrorMessage("")}
                        ContentProps={{ 'aria-errormessage': 'message-id', }}
                        message={<span id="message-id" >{errorMessage}</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="secondary"
                                className={classes.close}
                                onClick={() => setErrorMessage("")}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </div>
            </div>
        </React.Fragment>


    );
}

SigninContainer.defaultProps = {
    textColor: "black",
    userInfo: {
        userName: '',
        password: '',
        remember: true
    },
    onLogin: () => { },
    isBack: true,
    onExplore: null,
    onSignUp: null,
    isInitCurrentQuestAndContentList: true
}

SigninContainer.propTypes = {
    color: PropTypes.oneOf(["white", "black"]),
};

export default SigninContainer;
