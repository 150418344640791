import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomComponents/CustomButtons/Button.js";


import { Collapse, DialogActions, DialogContent, DialogTitle, FormHelperText, LinearProgress } from '@material-ui/core';
//axios
import axiosConfig from "util/axios";
import { checkNull } from 'util/validations';
import { dangerColor } from 'assets/jss/material-dashboard-react';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles({
    labelRootError: {
        color: dangerColor[0],
        marginTop: -8
    },
});


const QuestionFlag = (props) => {
    const classes = useStyles();
    const { questionId, onClose, onSubmit } = props
    const [progress, setProgress] = useState(false);
    const [remark, setRemark] = useState('');
    const [errorRemark, setErrorRemark] = useState('');
    const [error,setError]=useState('');

    const postRemark = () => {
        setErrorRemark('')
        setError('')
        setProgress(true)
        // Validation
        let validationObject = {};
        checkNull("Remark", remark, "Remark is required", validationObject);

        if (Object.keys(validationObject).length != 0) {
            setProgress(false)
            setErrorRemark(validationObject.errorRemark)
        } else {
            // const id = (questionId.replace(/[^0-9\.]+/g, "") * 1);
            axiosConfig().post('/OnlineTest/QuestionFlag', {
                questionId: questionId,
                remark: remark
            }).then(response => {
                setProgress(false)
                if (response.data.isSuccess) {
                    setRemark('')
                    onSubmit(response.data.message)
                }else{
                    setError(response.data.message)
                }

            }).catch((error) => {
                setProgress(false)
            });
        }



    }
    return (
        <React.Fragment>

            <DialogTitle >
                Question flag
            </DialogTitle>

            <DialogContent>
                <Collapse in={error.length>0} style={{marginBottom:10}}>
                    <Alert severity="error">{error}</Alert>
                </Collapse>

                <textarea
                    value={remark}
                    onChange={e => setRemark(e.target.value)}
                    placeholder='Enter short description of the error'
                    rows="5"
                    cols="50"
                />
                <FormHelperText className={classes.labelRootError}>{errorRemark}</FormHelperText>
            </DialogContent>

            <DialogActions style={{ margin: 10 }}>
                <Button
                    outlined
                    color="primary"
                    onClick={() => onClose()}>
                    Not now
                </Button>
                <Button
                    color="primary"
                    onClick={() => postRemark()}>
                    Submit
                </Button>
            </DialogActions>
            {progress ? <LinearProgress color="secondary" /> : null}
        </React.Fragment>
    );
}

export default QuestionFlag 