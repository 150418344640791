import React, { useEffect, useState } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
//redux
import { connect } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'

// @material-ui/core
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { Divider } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// @material-ui/icons/
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

//Custom Components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import Header from "components/CustomComponents/Card/CardHeader.js";
import CardBody from "components/CustomComponents/Card/CardBody.js";
import Button from "components/CustomComponents/CustomButtons/Button.js";
import PageNavigation from 'components/CustomComponents/Navbars/PageNavigation';
import Card from "components/CustomComponents/Card/Card.js";
import Dialog from "components/CustomComponents/Dialog/Dialog";
import AlertDialog from "components/CustomComponents/Dialog/AlertDialog";


// Views
import StepContent from 'components/Views/ContentViewer/AssessmentViewer/components/QuestionAnswers'
import Summary from 'components/Views/ContentViewer/AssessmentViewer/components/Summary'
import Help from 'components/Views/ContentViewer/AssessmentViewer/components/Help'
import QuestionFlag from "./components/QuestionFlag";

//variables
import { CURRENT_DATE_TIME } from "variables/general.js";

//commonfunctions
import { clevertapEvents } from "util/commonfunctions";

//Lottie
import Lottie from 'react-lottie';
import animationData from 'assets/lotties/times-up';
// styles
import Styles from "assets/jss/components/views/assessmentStyle";
import "./styles.css";

const useStyles = makeStyles(Styles);

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

//Alert
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}



const Assessment = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const [minutes, setMinutes] = useState(props.test.totalDuration);
    const [seconds, setSeconds] = useState(0);


    const [mode, setMode] = React.useState("out-in");
    const [helpDialog, setHelpDialog] = React.useState(false);
    const [alertDialog, setAlertDialog] = React.useState(false);
    const [isTimesup, setTimesup] = React.useState(false);

    const [revieweMode, setRevieweMode] = React.useState(false);


    const [currentQuestion, setCurrentQuestion] = React.useState(null);
    const [flagAlert, setFlagAlert] = React.useState(false);
    const [flagDialog, setDialog] = React.useState(false);

    const [snackbarMessage, setSnackbarMessage] = React.useState("");


    useEffect(() => {
        if (props.test.testAttemptedDuration > 0) {
            let totalSeconds = Number(props.test.testAttemptedDuration);
            totalSeconds %= 3600;
            let newMinutes = Math.floor(totalSeconds / 60);

            if (Number(props.test.totalDuration) <= newMinutes) {
                newMinutes = minutes - (newMinutes + 1);
                // const newSeconds = 60 - (totalSeconds % 60);
                setMinutes(0)
            } else {
                newMinutes = minutes - (newMinutes + 1);
                const newSeconds = 60 - (totalSeconds % 60);
                setMinutes(newMinutes)
                setSeconds(newSeconds)
            }
        }
    }, [])

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (props.test.testAttemptCount > 0) {
                clearInterval(myInterval)
            }
            else {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(myInterval)
                        setTimesup(true);
                        setTimeout(() => {
                            setTimesup(false);
                            handlePostTestAttempt()
                        }, 3000);
                    } else {
                        setMinutes(minutes - 1);
                        setSeconds(59);
                    }
                }
                if (seconds !== 0 || minutes !== 0) {
                    props.updateTestAttemptedDuration(1)
                }

            }

        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    }, [seconds]);



    const totalSteps = () => {
        return props.questions.qadd.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };


    const handleNext = () => {
        const newActiveStep = activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleReviewe = () => {
        setRevieweMode(true)
        setActiveStep(0);
    };

    /*-----------------------handle Alert Close Dialog--------------------*/
    const handleAlertCloseDialog = () => {
        setAlertDialog(false);
    };

    /*-----------------------handle Alert Open Dialog--------------------*/
    const handleAlertOpenDialog = () => {
        setAlertDialog(true);
    };

    /*-----------------------handle Alert onOK--------------------*/
    const handleAlertOK = async () => {
        handleAlertCloseDialog()
        await handlePostTestAttempt()
    };



    /*-----------------------handle Help Close Dialog--------------------*/
    const handleCloseDialog = () => {
        setHelpDialog(false);
    };


    /*-----------------------handle Help Open Dialog--------------------*/
    const handleOpenDialog = () => {
        setHelpDialog(true);
    };

    /*-----------------------handle open Flag Alert--------------------*/
    const handleCloseFlagAlert = () => {
        setCurrentQuestion(null)
        setFlagAlert(false);
    };

    /*-----------------------handle close Flag Alert-----------------*/
    const handleOpenFlagAlert = (question) => {
        setCurrentQuestion(question)
        setFlagAlert(true);
    };
    /*-----------------------handle Flag Alert onOK--------------------*/
    const handleFlagAlertOK = async () => {
        setFlagAlert(false);
        setDialog(true)
    };


    /*-----------------------handle Help Close Dialog--------------------*/
    const handleCloseFlagDialog = () => {
        setCurrentQuestion(null)
        setDialog(false)
    };


    /*-----------------------handle Close Snackbar--------------------*/
    const handleClose = () => {
        setSnackbarMessage("");
    }
    /*-----------------------handle Close Dialog--------------------*/
    const onSubmit = (message) => {
        setDialog(false);
        setSnackbarMessage(message);
    }




    const handlePostTestAttempt = async () => {
        const skipCount = props.answers.reduce((Count, currentValue) => Count + (currentValue.isQuestionSkipped === "0" ? 1 : 0), 0);
        const rightCount = props.answers.reduce((Count, currentValue) => Count + parseInt(currentValue.isAnsweredCorrect), 0);
        const wrongCount = props.answers.reduce((Count, currentValue) => Count + (currentValue.isAnsweredCorrect === "0" && currentValue.isQuestionSkipped !== "0" ? 1 : 0), 0);
        const marksObtained = props.answers.reduce((Count, currentValue) => Count + parseFloat(currentValue.questionMarksObtained), 0);
        const answers = props.answers
        //const actualDuration = (props.test.totalDuration * 60) - ((parseInt(minutes) * 60) + seconds)
        const bodyData = {
            "testTrackingId": "", // Don't set this
            "userId": props.userInfo.userId,
            "locationId": props.userInfo.locationId,
            "groupId": props.userInfo.groupId,
            "questId": props.quest.questId,
            "contentId": props.content.contentId,
            "testCode": props.content.testCode,
            "testActualDuration": props.test.totalDuration,
            "testAttemptedDuration": "" + props.test.testAttemptedDuration,
            "testTotalMarks": "" + props.test.totalMark,
            "marksObtained": "" + marksObtained,
            "totalQuestion": "" + props.test.totalQuestion,
            "skipCount": "" + skipCount,
            "rightCount": "" + rightCount,
            "wrongCount": "" + wrongCount,
            "percentage": "0",
            "attemptedDate": "" + CURRENT_DATE_TIME(),
            "question": answers,
            "missionActivitySummaryId": "" // Don't set this
        }
        props.updateTestAttemptCount() //for check current Test is submit or not
        await props.onPostTestAttempt(bodyData)
        //clevertap
        clevertapFun('Testended')
    }


    /*-----------------------handle Page--------------------*/
    const handleClosePage = () => {
        props.setCurrentTestHistory({})
        if (sm) {
            props.history.replace('/topics')
        }
        else {
            props.history.replace('/task')
        }
    };

    /*-----------------------clevertap--------------------*/
    const clevertapFun = (EventName) => {
        const eventData = JSON.stringify({
            'MisssionName': props.mission.missionName,
            'TestName': props.test.testName
        });
        clevertapEvents(EventName, eventData);
    }




    return (
        <div>
            {/*-----PageNavigation----- */}
            <PageNavigation
                // disabled={(props.test.testAttemptCount > 0 || revieweMode) ? true : false}
                logoText={props.content.contentDisplayName}
                alert={true}
                alertMessage={(props.test.testAttemptCount > 0 || revieweMode)
                    ? 'Please close the test.'
                    : 'You have started the test, Please complete the test before you leave.'}
            />
            {/*-----Dialog----- */}
            {isTimesup &&
                <Backdrop open={isTimesup} className={classes.backdrop}>
                    <Lottie options={defaultOptions}
                        height={400}
                        width={400}
                        isStopped={!isTimesup} />
                </Backdrop>
            }



            {/*-----Alert Dialog----- */}
            <AlertDialog
                open={alertDialog}
                type="info"
                title={"Are you sure you want to Submit?"}
                ContentText={null}
                cancelable={() => { }}
                action={[
                    {
                        text: 'Cancel',
                        onPress: handleAlertCloseDialog,
                        style: 'cancel'
                    },
                    { text: 'OK', onPress: handleAlertOK }
                ]}
            />

            {/*-----Alert Dialog----- */}
            <AlertDialog
                open={flagAlert}
                type="info"
                title={"Do you want to report error(s) in this question?"}
                ContentText={null}
                cancelable={() => { }}
                action={[
                    {
                        text: 'Cancel',
                        onPress: handleCloseFlagAlert,
                        style: 'cancel'
                    },
                    { text: 'OK', onPress: handleFlagAlertOK }
                ]}
            />

            {/*-----Dialog Help----- */}
            {helpDialog
                ? <Dialog open={helpDialog} maxWidth={"xs"} scroll="paper" TransitionComponent={Transition} >
                    <Help onClose={handleCloseDialog} />
                </Dialog>
                : null
            }

            {/*-----Dialog Flag----- */}
            {flagDialog
                ? <Dialog open={flagDialog} maxWidth={"xs"} scroll="paper" TransitionComponent={Transition} >
                    <QuestionFlag
                        questionId={currentQuestion}
                        onClose={handleCloseFlagDialog}
                        onSubmit={onSubmit}
                    />
                </Dialog>
                : null
            }


            {/* ------Snackbar------ */}
            <Snackbar open={snackbarMessage ? true : false} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={'success'}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            {/*-----page-container----- */}
            <div className='assm-instruction-container'>

                {/*-----Grid----- */}
                <GridContainer className={classes.gridContainer}>
                    <GridItem xs={12} sm={12} md={12} className={classes.cardIconStyle} />
                </GridContainer>

                <GridContainer className={classes.gridContainer1}>
                    <GridItem xs={12} sm={12} md={7} className={classes.centerContainer}>
                        <Card className={classes.cardStyle1}>
                            <Paper elevation={0} style={{ borderTopRightRadius: 20, borderTopLeftRadius: 20, height: '100%' }}>

                                {props.test.testAttemptCount === 0 ? (<React.Fragment>
                                    <Header className={classes.cardHeader} title={minutes + ":" + (seconds < 10 ? `0${seconds}` : seconds)} >
                                        {/* <div>{props.test.testAttemptedDuration}</div> */}
                                        {/* <Button color="primary" round outlined className={classes.buttonStyle} onClick={handleOpenDialog}>
                                            Help
                                        </Button> */}
                                    </Header>
                                    <Divider></Divider>
                                </React.Fragment>
                                ) : null}


                                <CardBody className={classes.stepperCardBody}>
                                    <GridContainer>
                                        {props.test.testAttemptCount === 0 || revieweMode
                                            ? <Stepper nonLinear activeStep={activeStep} className={`scroll ${classes.stepperStyle}`}>
                                                {props.questions.qadd.map((question, index) => (
                                                    <Step key={"btn" + question.questionId}>
                                                        <StepButton onClick={handleStep(index)} completed={props.answers[index].isQuestionSkipped == !0} />
                                                    </Step>
                                                ))}
                                            </Stepper>
                                            : null}
                                    </GridContainer>
                                </CardBody>
                            </Paper>
                        </Card>
                    </GridItem>
                </GridContainer>

                <GridContainer className={classes.gridContainer2}>
                    <GridItem xs={12} sm={12} md={7} className={classes.gridItem}>
                        <Card className={`scroll ${classes.cardStyle2}`}>
                            <Paper elevation={0} style={{ borderTopRightRadius: 20, borderTopLeftRadius: 20, height: '100%' }}>

                                <CardBody className={classes.questioAnswersCardBody}>
                                    <GridContainer >
                                        <GridItem xs={12} sm={12} md={12}>
                                            {props.test.testAttemptCount > 0 && !revieweMode ? (
                                                <Summary {...props}></Summary>
                                            ) : (
                                                <SwitchTransition mode={mode}>
                                                    <CSSTransition
                                                        key={activeStep}
                                                        addEndListener={(node, done) => {
                                                            node.addEventListener("transitionend", done, false);
                                                        }}
                                                        classNames="asm-fade"
                                                    >
                                                        <StepContent
                                                            {...props.questions.qadd[activeStep]}
                                                            reviewe={revieweMode}
                                                            onFlag={handleOpenFlagAlert}
                                                        />
                                                    </CSSTransition>
                                                </SwitchTransition>

                                            )}
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Paper >
                        </Card >
                    </GridItem >
                </GridContainer >

                <GridContainer className={classes.gridContainer4}>
                    <GridItem xs={12} sm={12} md={7} className={classes.centerContainer}>
                        <Card className={classes.cardStyle3}>
                            <Paper elevation={0} style={{ borderTopRightRadius: 20, borderTopLeftRadius: 20, height: '100%' }}>

                                <GridContainer >

                                    <GridItem xs={12} sm={12} md={12}>

                                        {props.test.testAttemptCount > 0 ? (
                                            revieweMode
                                                ? <div className={classes.divButtonsStyle}>
                                                    <div style={{ visibility: activeStep === 0 ? 'hidden' : 'unset' }}>
                                                        <Button simple={true} color="primary" className={classes.buttonBackNext} disabled={activeStep === 0} onClick={handleBack} startIcon={<ArrowBackIosIcon />} >
                                                            Back
                                                        </Button>
                                                    </div>
                                                    <Button color="primary" round outlined onClick={handleClosePage} className={classes.buttonStyle}>
                                                        Close
                                                    </Button>

                                                    <div style={{ visibility: isLastStep() ? 'hidden' : 'unset' }}>
                                                        <Button simple={true} color="primary" className={classes.buttonBackNext} onClick={handleNext} disabled={isLastStep()} endIcon={<ArrowForwardIosIcon />}>
                                                            Next
                                                        </Button>
                                                    </div>
                                                </div>
                                                : <div className={classes.divButtonsStyle}>
                                                    <Button color="primary" className={classes.buttonStyle} onClick={handleReviewe} >
                                                        Review
                                                    </Button>

                                                    <Button onClick={handleClosePage} className={classes.buttonStyle}>
                                                        Close
                                                    </Button>
                                                </div>

                                        ) : (
                                            <div className={classes.divButtonsStyle}>
                                                <div style={{ visibility: activeStep === 0 ? 'hidden' : 'unset' }}>
                                                    <Button simple={true} color="primary" className={classes.buttonBackNext} disabled={activeStep === 0} onClick={handleBack} startIcon={<ArrowBackIosIcon />} >
                                                        Back
                                                    </Button>
                                                </div>

                                                <Button color="primary" round outlined className={classes.buttonStyle} onClick={handleAlertOpenDialog}>
                                                    End Test
                                                </Button>

                                                <div style={{ visibility: isLastStep() ? 'hidden' : 'unset' }}>
                                                    <Button simple={true} color="primary" className={classes.buttonBackNext} onClick={handleNext} disabled={isLastStep()} endIcon={<ArrowForwardIosIcon />}>
                                                        Next
                                                    </Button>
                                                </div>
                                            </div>

                                        )}
                                    </GridItem>
                                </GridContainer>

                            </Paper>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div >

        </div >

    )
}


const mapSateToProps = state => {
    return {
        test: state.content.current_test,
        questions: state.questions.questions,
        answers: state.questions.answers,
        content: state.content.current_content,
        userInfo: state.user.userinfo,
        quest: state.quest.current_quest,
        mission: state.content.current_mission,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // onInitTestAnswers: (answers) => dispatch(questActions.initTestAnswers(answers)),
        updateTestAttemptCount: () => dispatch(questActions.updateTestAttemptCount()),// For check Current Test is attempted or not 
        onPostTestAttempt: (userId) => dispatch(questActions.postTestAttempt(userId)), // //also update content,current_cuntent and quest_progress 
        updateTestAttemptedDuration: (duration) => dispatch(questActions.updateTestAttemptedDuration(duration)),
        setCurrentTestHistory: (data) => dispatch(questActions.setCurrentTestHistory(data)),

    }
}


export default connect(mapSateToProps, mapDispatchToProps)(Assessment);
