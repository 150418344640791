import { makeStyles } from "@material-ui/core/styles";

import {
  successColor,
  secondaryColor
} from "assets/jss/material-dashboard-react.js";


const Style = makeStyles((theme) => ({

  UserDetailsLayout: {
    width: 'auto',
    display: 'flex', 
    minHeight: '100vh',
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      paddingTop: theme.spacing() * 10,
      width: 440,
      minHeight:  'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    textAlign: 'center',
  },
  paperContainer: {
    // backgroundColor: secondaryColor[1],
    position: "relative",
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      borderRadius: 20,
    },
  },
  UserDetailsPaper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing() * 2}px ${theme.spacing() * 3}px ${theme.spacing() * 3}px`,
   
  },
  stepContent: {
    width: '100%',
    height: '100%',
    minHeight: 200
  },


  actionsGridItem: {
    marginTop: 20,
    justifyItems: 'flex-end',
    alignItems: 'flex-end',
    display: 'flex'
  },
  actionsContainer: {
    // padding: 15,
    width: '100%',
    display: 'flex',
    justifyContent: "space-between"
  },


  paper1: {
    textAlign: 'center',
    // boxShadow: '0px 8px 10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing() * 2}px ${theme.spacing() * 3}px ${theme.spacing() * 3}px`,
  },

  form1: {
    maxWidth: '328px',
    width: '100%', // Fix IE11 issue.
    // marginTop: theme.spacing(),
  },
  submit: {
    borderRadius: 20,
    maxWidth: '328px',
    maxHeight: '48px',
    marginTop: theme.spacing() * 3
  },
  titleHeader1:{
    padding:0,
    color: '#fff !important',
  },
  labelRootError: {
    color: '#fff !important',
    marginTop: 0
  },
  labelRoot:{
    color: '#fff !important',
  },
  inputUnderlinewhite: {
    color: '#fff',
    "&:before": {
      borderBottom: '1px solid rgb(255 255 255) !important'
    },
    "&:after": {
      borderBottom: '2px solid black !important'
    }
  },


}));

export default Style;